
import { Component, Vue } from "vue-property-decorator";
import HousfyPage from "@/components/protected/HousfyPage.vue";
import ToolsList from "@/components/ToolsList.vue";
import { HousfyBannerWithCheckList } from "housfy-ui-lib";
import { IBackOptions } from "@/models/BackOptions";
import { TranslateResult } from "vue-i18n";
import MYHExternalRoutes from "@/services/MYHExternalRoutes";
import { resolveRoutePath } from "@/router";
import { TabbarIds } from "@/enums/HousfyPage";

@Component({
  name: "Tenant",
  components: {
    HousfyPage,
    HousfyBannerWithCheckList,
    ToolsList,
  },
})
export default class Tenant extends Vue {
  activeTabId = TabbarIds.ACTIVITY;

  get backOptions(): IBackOptions {
    return {
      enabled: true,
      text: this.$t("common.back"),
      minimal: this.$mqm,
      preventDefault: false,
      fallbackHref: resolveRoutePath(MYHExternalRoutes.myhHome()),
    };
  }
  get bannerList(): TranslateResult[] {
    return [
      this.$t("common.findHomesInYourCity"),
      this.$t("common.saveYourSearchesAndFavouriteHouses"),
      this.$t("common.scheduleYourVisitsInstantly"),
    ];
  }
  handleBannerClick() {
    this.$navigate(MYHExternalRoutes.portal());
  }
}
